import { FC, useCallback, useMemo, useState } from 'react'
import { groupBy } from 'lodash'
import InlineLink from 'src/components/01-atoms/InlineLink'
import PackageListTableCell from 'src/components/01-atoms/PackageListTableCell'
import Tag, { Color } from 'src/components/01-atoms/Tag'
import ManifestPackageContents from 'src/components/02-molecules/ManifestPackageContents'
import Table from 'src/components/02-molecules/Table'
import { gbmcOrderLink, gbmcSuborderLink } from 'src/utils/gbmcLink'
import useAppParams from 'src/utils/hooks/useAppParams'
import useGetPackagesWithLabelIssue from 'src/utils/hooks/useGetPackagesWithLabelIssue'
import { useIntervalWhen } from 'rooks'
import LoadingBar from 'src/components/01-atoms/LoadingBar'
import classNames from 'classnames'

const MDASH_FULFILLMENT_SYSTEM = 4
const REFRESH_INTERVAL_IN_SECONDS = 30
const SORT_OPTIONS = {
  name: 'Name',
  issues: 'Issues',
}

const List: FC = () => {
  const { toShipOnAsString } = useAppParams()
  const [ timer, setTimer ] = useState( 0 )
  const [ sortBy, setSortBy ] = useState<keyof typeof SORT_OPTIONS>( 'issues' )
  const autoRefreshIn = useMemo(() => REFRESH_INTERVAL_IN_SECONDS - timer, [ timer ])
  const [{ data, fetching }, refetch ] = useGetPackagesWithLabelIssue({
    variables: {
      limit: 10000,
    },
    requestPolicy: 'cache-and-network',
  })
  const results = data?.manifestPackagesWithLabelIssues ?? []
  const groups = groupBy(
    results.sort(( a, b ) => {
      switch ( sortBy ) {
        case 'issues':
          return (
            ( results.filter(( x ) => x.mdashAccount.id === b.mdashAccount.id ).length ?? 0 ) -
            ( results.filter(( x ) => x.mdashAccount.id === a.mdashAccount.id ).length ?? 0 )
          )
        default:
          return a.mdashAccount.name.localeCompare( b.mdashAccount.name )
      }
    }),
    ( x ) => x.mdashAccount.name
  )

  const splitCarrierService = useCallback(
    ( carrierServiceName = '' ) => ({
      carrier: carrierServiceName.split( /\s/ ).slice( 0, 1 ),
      service: carrierServiceName.split( /\s/ ).slice( 1 ).join( ' ' ),
    }),
    []
  )

  useIntervalWhen(
    () => {
      if ( timer >= REFRESH_INTERVAL_IN_SECONDS ) {
        refetch()
        setTimer( 0 )
      } else {
        setTimer(( x ) => x + 1 )
      }
    },
    1000,
    true
  )

  if ( fetching ) return 'Fetching data. Please wait...'

  return (
    <div>
      <div className="flex justify-end items-start gap-4">
        <div className="flex justify-between items-center text-xs">
          <div className="pr-2">Sort Account By:</div>
          <button
            type="button"
            className={classNames(
              'px-2 border-b-2 transition-all duration-300',
              sortBy === 'name'
                ? 'font-bold text-gb-blue-700 border-gb-blue-700'
                : 'text-gb-gray-800 border-gb-gray-500'
            )}
            onClick={() => setSortBy( 'name' )}
          >
            Name
          </button>
          <button
            type="button"
            className={classNames(
              'px-2 border-b-2 transition-all duration-300',
              sortBy === 'issues'
                ? 'font-bold text-gb-blue-700 border-gb-blue-700'
                : 'text-gb-gray-800 border-gb-gray-500'
            )}
            onClick={() => setSortBy( 'issues' )}
          >
            Number of Issues
          </button>
        </div>
        <div className="grid justify-end">
          <div className="text-xs text-right w-full">
            {autoRefreshIn > 1 && (
              <button
                className="text-gb-blue-700 font-bold underline"
                type="button"
                onClick={() => {
                  setTimer( REFRESH_INTERVAL_IN_SECONDS - 1 )
                }}
              >
                Auto-update
              </button>
            )}
            {autoRefreshIn > 1 && ` in ${autoRefreshIn} seconds...`}
            {autoRefreshIn === 1 && 'Auto-update in a moment...'}
            {autoRefreshIn < 1 && 'Fetching fresh data...'}
          </div>
          <div className="w-[320px] max-w-[100%]">
            <LoadingBar
              height="extra-small"
              motion="smooth"
              current={timer}
              total={REFRESH_INTERVAL_IN_SECONDS}
            />
          </div>
        </div>
      </div>
      {!fetching && results.length === 0 ? (
        'No issues today 🎉'
      ) : (
        <div className="grid gap-4">
          {Object.entries( groups ).map(([ mdashAccountName, packages ]) => (
            <div key={packages[0].mdashAccount.id}>
              <div className="flex justify-between items-end border-b mb-2 sticky top-[104px] z-10 bg-gb-gray-50 pt-2">
                <InlineLink
                  target={`_manifest-${packages[0].mdashAccount.id}-${toShipOnAsString}`}
                  to={`/${packages[0].mdashAccount.id}/manifest/${toShipOnAsString}`}
                  className="text-xl"
                >
                  <div className="flex gap-2 items-center">
                    {mdashAccountName}
                    {packages[0].fulfillmentSystem !== MDASH_FULFILLMENT_SYSTEM && (
                      <Tag color={Color.AMBER} size="medium">
                        System {packages[0].fulfillmentSystem}
                      </Tag>
                    )}
                  </div>
                </InlineLink>
                <div>
                  {packages.length} {packages.length === 1 ? 'issue' : 'issues'}
                </div>
              </div>
              <Table
                tableClasses="package-list-table"
                headers={[
                  { name: 'Order ID' },
                  { name: 'Package ID' },
                  { name: 'Products' },
                  { name: 'Recipient' },
                  { name: 'Carrier' },
                ]}
              >
                {packages.map(( x ) => (
                  <tr key={x.id}>
                    <PackageListTableCell className="w-32">
                      <InlineLink
                        target={`_gbmc-order-${x.orderId}`}
                        href={gbmcOrderLink( x.orderId )}
                      >
                        {x.orderId}
                      </InlineLink>
                    </PackageListTableCell>
                    <PackageListTableCell className="w-32">
                      <InlineLink target={`_gbmc-suborder-${x.id}`} href={gbmcSuborderLink( x.id )}>
                        {x.id}
                      </InlineLink>
                    </PackageListTableCell>
                    <PackageListTableCell className="w-1/4">
                      <ManifestPackageContents
                        lineItems={x.lineItems.map(( li ) => ({
                          ...li,
                          quantity: li.quantityOrWeight,
                          name: li.product?.name ?? li.name,
                        }))}
                      />
                    </PackageListTableCell>
                    <PackageListTableCell className="w-64">
                      <div>{[ x.deliveryFirstName, x.deliveryLastName ].join( ' ' )}</div>
                      <div>{[ x.deliveryCity, x.deliveryState, x.deliveryCountry ].join( ', ' )}</div>
                    </PackageListTableCell>
                    <PackageListTableCell className="w-1/3">
                      <b>
                        {splitCarrierService( String( x.currentShippingLabel?.serviceName )).carrier}
                      </b>
                      <div>
                        {splitCarrierService( String( x.currentShippingLabel?.serviceName )).service}
                      </div>
                      <div className="text-red-600 italic">
                        {x.currentShippingLabel?.carrierResponse}
                      </div>
                    </PackageListTableCell>
                  </tr>
                ))}
              </Table>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default List
