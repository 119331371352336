import { FC, useEffect, useState } from 'react'
import { loader } from 'graphql.macro'
import { useMutation } from 'urql'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import CustomerProfile, { ICustomerProfileProps } from 'src/components/05-pages/CustomerProfile'

import {
  IUpdatePurchaserAccountMutation,
  IUpdatePurchaserAccountMutationVariables,
} from 'src/graphql/mutations/updatePurchaserAccount.types'
import { CustomerFormProps } from './types'
import formSchema from './formSchema'

const updatePurchaserAccountMutation = loader(
  'src/graphql/mutations/updatePurchaserAccount.graphql'
)

interface ICustomerProfileFormProps
  extends Omit<
    ICustomerProfileProps,
    | 'handleSavePurchaserInfo'
    | 'purchaserSaveError'
    | 'purchaserFormFieldProps'
    | 'formState'
    | 'isSubmitting'
    | 'isSubmitSuccessful'
    | 'disableSaveButton'
  > {}

const Form: FC<ICustomerProfileFormProps> = ( props ) => {
  const [ purchaserSaveError, setPurchaserSaveError ] = useState<string>()
  const [ showSuccessMessage, setShowSuccessMessage ] = useState<boolean>( false )

  const { account } = props
  const { firstName, lastName, phone, email } = account
  const { register, handleSubmit, formState, reset, getFieldState, watch, clearErrors } =
    useForm<CustomerFormProps>({
      defaultValues: { firstName, lastName, phone, email },
      resolver: zodResolver( formSchema ),
      reValidateMode: 'onChange',
      mode: 'all',
    })

  const fieldStates = {
    firstName: getFieldState( 'firstName' ),
    lastName: getFieldState( 'lastName' ),
    phone: getFieldState( 'phone' ),
    // email: getFieldState( 'email' ),
  }

  const fieldProps = {
    firstName: {
      ...register( 'firstName' ),
      error: fieldStates.firstName.error?.message,
      isInvalidExternally: fieldStates.firstName.invalid,
    },
    lastName: {
      ...register( 'lastName' ),
      error: fieldStates.lastName.error?.message,
      isInvalidExternally: fieldStates.lastName.invalid,
    },
    phone: {
      ...register( 'phone' ),
      error: fieldStates.phone.error?.message,
      isInvalidExternally: fieldStates.phone.invalid,
    },
    email: {
      readOnly: true,
      required: false,
      // ...register( 'email' ),
      // error: fieldStates.email.error?.message,
      // isInvalidExternally: fieldStates.email.invalid,
    },
  }

  const formValues = watch()
  useEffect(() => {
    if ( formState.isValid ) {
      setPurchaserSaveError( undefined )
      clearErrors([ 'firstName', 'lastName', 'phone', 'email' ])
      setTimeout(() => {
        setShowSuccessMessage( false )
      }, 5000 )
    }
  }, [ formValues, formState.isValid ])

  const [ , updatePurchaserAccount ] = useMutation<
    IUpdatePurchaserAccountMutation,
    IUpdatePurchaserAccountMutationVariables
  >( updatePurchaserAccountMutation )

  const savePurchaserInfo: SubmitHandler<CustomerFormProps> = ( data ) => {
    try {
      if ( formState.isDirty && !( account.id && formState.isValid )) {
        throw new Error( 'Invalid form data' )
      }
      setPurchaserSaveError( undefined )
      updatePurchaserAccount({
        purchaserAccountId: account.id,
        ...data,
      }).then(( response ) => {
        if ( response.error ) {
          throw new Error( response.error.message )
        }
        setShowSuccessMessage( true )
        reset( data )
      })
    } catch ( e ) {
      setPurchaserSaveError( `${e}` )
    }
  }

  if ( !account ) {
    return null
  }

  return (
    <CustomerProfile
      {...props}
      handleSavePurchaserInfo={handleSubmit( savePurchaserInfo )}
      purchaserSaveError={purchaserSaveError}
      purchaserFormFieldProps={fieldProps}
      disableSaveButton={!formState.isDirty || !formState.isValid}
      isSubmitting={formState.isSubmitting}
      formReset={() => reset()}
      isSubmitSuccessful={showSuccessMessage}
    />
  )
}

export default Form
