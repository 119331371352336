import * as z from 'zod'

/**
 * Make sure this schema matches the CustomerFormProps type in ./types.ts.
 */
const formSchema = z
  .object({
    firstName: z
      .string({ required_error: 'First name is required.' })
      .regex( /\w+/, { message: 'Please enter a valid first name.' }),
    lastName: z
      .string({ required_error: 'Last name is required.' })
      .regex( /\w+/, { message: 'Please enter a valid last name.' }),
    phone: z
      .string({ required_error: 'Phone number is required.' })
      .regex( /^(1?\d{10}(\s*(x|ext\.+|extension *)(\d+))?)$/, {
        message: 'Please enter a valid phone number.',
      }),
    email: z
      .string({ invalid_type_error: 'Email is required.', required_error: 'Email is required.' })
      .email({ message: 'Please enter a valid email address.' }),
  })
  .required()

export default formSchema
