import { FC, FormEventHandler, useState } from 'react'
import { max, min, times } from 'lodash'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'

import Button from 'src/components/01-atoms/Button'
import Grid from 'src/components/01-atoms/Grid'
import SectionCard from 'src/components/01-atoms/SectionCard'
import Alert from 'src/components/01-atoms/Alert'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import Tag, { Color } from 'src/components/01-atoms/Tag'

import CustomerAddressesBlock, {
  ICustomerAddressesBlockProps,
} from 'src/components/02-molecules/CustomerAddressesBlock'
import CustomerHistoryBlock, {
  ICustomerHistoryBlockProps,
} from 'src/components/02-molecules/CustomerHistoryBlock'
import CustomerSummaryBlock, {
  ICustomerSummaryBlockProps,
} from 'src/components/02-molecules/CustomerSummaryBlock'
import PurchaserInfoForm, {
  IPurchaserInfoForm,
} from 'src/components/02-molecules/PurchaserInfoForm'
import OrderSummaryAccordion, {
  IOrderSummaryAccordionProps,
} from 'src/components/03-organisms/OrderSummaryAccordion'

import { ICustomerAccount } from 'src/utils/types/ICustomerAccount'

export interface ICustomerProfileProps {
  /**
   * The customer account information.
   */
  account: ICustomerAccount

  /**
   * The link to place a new order.
   */
  placeNewOrderLink?: string

  /**
   * The customer account orders.
   */
  orders?: IOrderSummaryAccordionProps[]

  /**
   * The function to get the order summary contents.
   * @param orderId The order ID.
   * @returns The order summary contents.
   */
  handleGetOrderSummaryContents?: ( orderId: string ) => void

  /**
   * The function to request more orders.
   */
  handleViewMoreOrders?: () => void

  /**
   * Whether or not more orders are loading.
   */
  isLoadingMoreOrders?: boolean

  /**
   * The customer account metadata.
   */
  accountSummary: ICustomerSummaryBlockProps

  /**
   * The customer account history.
   */
  accountHistory: ICustomerHistoryBlockProps

  /**
   * The customer account addresses.
   */
  accountAddresses: ICustomerAddressesBlockProps['addresses']

  /**
   * The number of orders to show per page.
   */
  pageLimit?: number

  /**
   * The link to GBMC.
   */
  gbmcLink?: string

  /**
   * Whether to show a link to GBMC.
   */
  showLinkToGbmc?: boolean

  /**
   * The props for the purchaser form fields.
   */
  purchaserFormFieldProps?: IPurchaserInfoForm['fieldProps']

  /**
   * The function to save the purchaser information.
   */
  handleSavePurchaserInfo?: FormEventHandler<HTMLFormElement>

  /**
   * The error message, if one comes back when trying to save purchaser information.
   */
  purchaserSaveError?: string

  /**
   * Whether or not the form is submitting.
   */
  isSubmitting?: boolean

  /**
   * Whether or not the form was submitted successfully.
   */
  isSubmitSuccessful?: boolean

  /**
   * Whether or not to disable the save button.
   */
  disableSaveButton?: boolean

  /**
   * The function to run when the cancel button is clicked.
   */
  formReset?: () => void
}

const CustomerProfile: FC<ICustomerProfileProps> = ({
  account,
  placeNewOrderLink,
  orders = [],
  handleGetOrderSummaryContents = () => {},
  handleViewMoreOrders = () => {},
  isLoadingMoreOrders = false,
  accountSummary,
  accountHistory,
  accountAddresses,
  pageLimit = 5,
  gbmcLink,
  showLinkToGbmc = false,
  purchaserFormFieldProps,
  handleSavePurchaserInfo,
  purchaserSaveError,
  isSubmitting = false,
  isSubmitSuccessful = false,
  disableSaveButton = false,
  formReset = () => {},
}) => {
  const { firstName, lastName, email, phone, isGuest = false } = account
  const [ isEditing, setIsEditing ] = useState( false )

  const numOrdersShowing = orders.length
  const numOrdersTotal = accountSummary.numTotalOrders ?? 1

  const numOrdersToFetch = max([ min([ numOrdersTotal - numOrdersShowing, pageLimit ]), 0 ]) as number

  const accountType = isGuest
    ? {
        label: 'Guest Account',
        color: Color.ORANGE,
      }
    : {
        label: 'Registered Account',
        color: Color.EMERALD,
      }

  return (
    <>
      {!lastName || !firstName ? (
        <div data-testid="loading-name">
          <Skeleton className="w-full h-8" />
        </div>
      ) : (
        <div className="flex flex-col items-start md:flex-row gap-2 md:items-center">
          <h1 className="text-2xl font-semibold">
            {lastName}, {firstName}
          </h1>
          <Tag data-testid="account-type" color={accountType.color}>
            {accountType.label}
          </Tag>
          {showLinkToGbmc && (
            <a
              className="font-semibold text-sm text-gb-blue-600 hover:text-gb-blue-800"
              data-testid="gbmc-link"
              href={gbmcLink}
            >
              View in GBMC
            </a>
          )}
        </div>
      )}

      {placeNewOrderLink && (
        <div className="md:inline-block mt-4">
          <Button to={placeNewOrderLink} className="center">
            Place New Order
          </Button>
        </div>
      )}
      <Grid className="mt-6">
        <div className="col-span-12 lg:col-span-8 flex flex-col gap-4">
          <SectionCard
            title={
              <div className="flex justify-between items-center">
                Contact Information
                <StatusIconWrapper
                  className={classNames(
                    'text-green-500 transition-opacity ease-out duration-300 delay-100 motion-reduce:transition-none',
                    isSubmitSuccessful ? 'opacity-1' : 'opacity-0'
                  )}
                  size="small"
                  level="success"
                >
                  Saved
                </StatusIconWrapper>
              </div>
            }
            data-testid="contact-info"
          >
            {purchaserSaveError && <Alert type="error">{purchaserSaveError}</Alert>}
            <form
              onSubmit={( e ) => {
                e.preventDefault()
                setIsEditing( false )
                handleSavePurchaserInfo?.( e )
              }}
            >
              <fieldset disabled={isSubmitting}>
                <PurchaserInfoForm
                  fieldProps={{
                    firstName: {
                      ...purchaserFormFieldProps?.firstName,
                      defaultValue: firstName,
                      disabled: !isEditing,
                      required: true,
                    },
                    lastName: {
                      ...purchaserFormFieldProps?.lastName,
                      defaultValue: lastName,
                      disabled: !isEditing,
                      required: true,
                    },
                    phone: {
                      ...purchaserFormFieldProps?.phone,
                      defaultValue: phone,
                      disabled: !isEditing,
                      required: true,
                    },
                    email: {
                      ...purchaserFormFieldProps?.email,
                      defaultValue: email,
                      disabled: !isEditing,
                      helperText: '',
                      required: true,
                    },
                  }}
                >
                  <div
                    className={`col-span-12 sm:col-span-4 sm:col-start-5 xl:col-end-13 ${
                      isEditing ? 'xl:col-span-4' : 'xl:col-span-2'
                    }`}
                  >
                    <div className="flex gap-4 pt-6">
                      {isEditing ? (
                        <>
                          <Button
                            type="reset"
                            onClick={() => {
                              formReset()
                              setIsEditing( false )
                            }}
                            className="center w-full"
                            data-testid="cancel-button"
                            outline
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="center w-full"
                            data-testid="save-button"
                            disabled={disableSaveButton}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <Button
                          type="button"
                          className="center w-full"
                          data-testid="edit-button"
                          onClick={() => setIsEditing( true )}
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </div>
                  </div>
                </PurchaserInfoForm>
              </fieldset>
            </form>
          </SectionCard>
          <SectionCard
            title="Order History"
            data-testid="order-history"
            contentClassName="flex flex-col gap-4"
          >
            {orders.length > 0
              ? orders?.map(( order ) => (
                  <div data-testid="order-summary" key={`order-${order.orderId}`}>
                    <OrderSummaryAccordion handleOpen={handleGetOrderSummaryContents} {...order} />
                  </div>
                ))
              : 'No orders found.'}
            {isLoadingMoreOrders &&
              numOrdersToFetch > 0 &&
              times( numOrdersToFetch, ( i ) => (
                <Skeleton key={`order-loading-${i}`} className="w-full h-[88px] md:h-[72px]" />
              ))}
            {accountSummary.numTotalOrders > numOrdersShowing ? (
              <div className="center">
                <Button
                  outline
                  disabled={
                    numOrdersShowing >= accountSummary.numTotalOrders || isLoadingMoreOrders
                  }
                  onClick={() => handleViewMoreOrders()}
                >
                  Load More Orders
                </Button>
              </div>
            ) : (
              accountSummary.numTotalOrders > 0 && (
                <div className="pt-4 border-t border-gb-gray-400 center">
                  <p>You&apos;ve reached the end of this customer&apos;s order history.</p>
                </div>
              )
            )}
          </SectionCard>
        </div>
        <div className="col-span-12 gap-4 grid grid-cols-1 auto-rows-min md:grid-cols-2 lg:col-span-4 lg:grid-cols-1">
          <CustomerSummaryBlock {...accountSummary} />
          <CustomerHistoryBlock {...accountHistory} />
          {!isGuest && <CustomerAddressesBlock addresses={accountAddresses} />}
        </div>
      </Grid>
    </>
  )
}

export default CustomerProfile
