import { FC, createRef, useEffect, useState, ReactEventHandler, ReactElement } from 'react'
import { faBoxHeart, faGift, faPlateUtensils } from '@fortawesome/pro-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons'
import classNames from 'classnames'
import { isEmpty } from 'lodash'

import Grid from 'src/components/01-atoms/Grid'
import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import PackingSlipSection from 'src/components/01-atoms/PackingSlipSection'
import PrintablePage, { PageSize } from 'src/components/01-atoms/PrintablePage'
import ShippingLabel from 'src/components/01-atoms/ShippingLabel'
import PackageInstructions from 'src/components/01-atoms/PackageInstructions'
import HideWhenNotPrintable from 'src/components/01-atoms/HideWhenNotPrintable'
import PackageNotice from 'src/components/01-atoms/PackageNotice'
import PackageInfo, { IPackageInfoProps } from 'src/components/02-molecules/PackageInfo'
import PackingSlipPackageContents, {
  IPackingSlipPackageContentsProps,
} from 'src/components/02-molecules/PackingSlipPackageContents'
import { PackageType } from 'src/components/03-organisms/SlipNLabel/PackageType'

import { getInValue } from 'src/utils/helpers/printValueConversions'
import { ReactComponent as WeShipWithGoldbelly } from 'src/assets/icons/powered-by-gb.svg'
import { ReactComponent as WeShipWithGoldbellyV2 } from 'src/assets/we-ship-on-stacked-v2.svg'
import checklistIcon from 'src/assets/icons/checklist.svg'
import { IVersioned } from 'src/utils/types/Versioned'
import usaToCan from 'src/assets/usa-to-can.svg'
import DryIceSticker from 'src/components/01-atoms/DryIceSticker'
import DryIceStickerPlaceholder from 'src/components/01-atoms/DryIceStickerPlaceholder'

interface ISlipNLabelUsLetterProps extends Omit<IPackageInfoProps, 'showBarcode'>, IVersioned {
  /**
   * The type of order being fulfilled. The value of the enum provides the heading text for the
   * order instructions.
   */
  packageType?: PackageType

  /**
   * The URL for the merchant's logo.
   */
  whiteLabelLogoPath?: string | null

  /**
   * The URL to the shipping label image.
   */
  labelImgPath?: string | null

  /**
   * The URL to the QR Code image.
   */
  qrCodeImgPath?: string | null

  /**
   * The country where the package is being delivered.
   */
  deliveryCountry: string

  /**
   * The list items in this package.
   */
  packageContents: IPackingSlipPackageContentsProps['lineItems']

  /**
   * The name of the person receiving the package.
   */
  recipientsName: string

  /**
   * The name of the person sending the package.
   */
  sendersName?: string

  /**
   * The gift message from the sender.
   */
  giftMessage?: string

  /**
   * Method to run after the image loads.
   */
  handleLabelImageLoad?: ReactEventHandler<HTMLImageElement>

  /**
   * Method to run if the image returns an error.
   */
  handleLabelImageError?: ReactEventHandler<HTMLImageElement>

  /**
   * Custom instructions to display on the packing slip.
   */
  customInstructions?: ReactElement | string

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string

  /**
   * Whether to show the package barcode.
   */
  showPackageBarcode?: boolean

  /**
   * The weight of the dry ice in kilograms.
   */
  dryIceWeightInKg?: number

  /**
   * Whether to show the dry ice sticker.
   */
  showDryIceSticker?: boolean

  /**
   * Whether to use the placeholder instead of the dry ice sticker.
   */
  showDryIcePlaceholder?: boolean
}

const USLetter: FC<ISlipNLabelUsLetterProps> = ({
  merchantName,
  packageType = PackageType.DEFAULT,
  whiteLabelLogoPath,
  labelImgPath,
  qrCodeImgPath,
  deliveryCountry = 'US',
  packageContents,
  recipientsName,
  sendersName,
  giftMessage,
  handleLabelImageLoad = () => {},
  handleLabelImageError = () => {},
  customInstructions,
  className,
  version = 1,
  showPackageBarcode = false,
  dryIceWeightInKg = 0,
  showDryIceSticker = false,
  showDryIcePlaceholder = false,
  ...packageInfoProps
}) => {
  const [ isPrintable, setIsPrintable ] = useState( !isEmpty( labelImgPath ))
  const [ listTooLong, setListTooLong ] = useState( false )
  const contentsElem = createRef<HTMLDivElement>()

  const prescribedHeightInInches = 3.25

  useEffect(() => {
    if ( contentsElem.current?.clientHeight ) {
      setListTooLong( getInValue( contentsElem.current?.clientHeight ) >= prescribedHeightInInches )
    }
  }, [ contentsElem ])

  const hasWhiteLabelLogo = !!whiteLabelLogoPath
  const headingText = packageType
  const hasGiftMessage = !!giftMessage && !!sendersName
  const hasSKUs = packageContents.filter(( x ) => Number( x.sku?.length || 0 ) > 0 ).length
  const useHugeStamp = deliveryCountry === 'CA' && !hasSKUs

  const addDryIceSticker = showDryIceSticker && dryIceWeightInKg > 0

  return (
    <HideWhenNotPrintable isPrintable={isPrintable}>
      <div data-testid="printable-us-letter">
        <PrintablePage size={PageSize.US_LETTER_STRICT} className={className}>
          {version === 1 ? (
            <>
              <div
                data-testid="packing-slip-v1"
                className="h-1/2 relative p-[0.25in] text-black font-semibold"
              >
                <div className="w-full flex flex-row gap-x-6">
                  <div className="flex-grow">
                    {hasWhiteLabelLogo ? (
                      <img
                        src={whiteLabelLogoPath}
                        alt={`${merchantName} logo`}
                        className="max-h-18 max-w-[3in]"
                      />
                    ) : (
                      <Logo type={LogoType.STACKED} className="max-h-[0.75in] max-w-[1.25in]" />
                    )}
                  </div>
                  {hasWhiteLabelLogo && <WeShipWithGoldbelly className="h-18 flex-shrink" />}
                  <PackageInfo {...packageInfoProps} className="flex-shrink" showBarcode />
                </div>

                <div className="flex pt-2 gap-x-2">
                  <div className="w-3/5">
                    <div className="font-bold uppercase">{merchantName} Order</div>
                    <div className="w-full flex mt-2 gap-x-4">
                      <div className="flex place-items-center w-1/3">
                        {qrCodeImgPath && (
                          <img
                            src={qrCodeImgPath}
                            alt="QR Code for order instructions"
                            className="w-full aspect-square"
                          />
                        )}
                      </div>
                      <PackageInstructions headingText={headingText} className="w-2/3">
                        {customInstructions || (
                          <>
                            Use your smart phone&apos;s camera to scan the QR Code. Visit{' '}
                            <span className="underline font-bold">help.goldbelly.com</span> for
                            order questions.
                          </>
                        )}
                      </PackageInstructions>
                    </div>

                    <div className="mt-3">
                      <span className="font-bold uppercase">For:</span> {recipientsName}
                      {giftMessage && (
                        <p className="my-2 text-xs leading-4 max-h-28 overflow-hidden break-word">
                          {giftMessage}
                        </p>
                      )}
                      {sendersName && (
                        <p>
                          <span className="font-bold uppercase">From:</span> {sendersName}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="h-full w-2/5 relative">
                    <div
                      className={classNames(
                        { 'max-h-[3.25in]': listTooLong },
                        'w-full overflow-hidden'
                      )}
                      ref={contentsElem}
                    >
                      <PackingSlipPackageContents lineItems={packageContents} />
                    </div>
                    {listTooLong && (
                      <div className="absolute z-20 inset-x-0 top-[2.75in] w-[300px]">
                        <PackageNotice orderId={packageInfoProps.orderId} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="border-b-2 border-black border-dashed w-full absolute inset-x-0 bottom-0 text-center">
                  Please include the above slip inside the package
                </div>
              </div>
              <div className="h-1/2 screen:center print:flex print:items-end print:justify-center">
                {labelImgPath && (
                  <ShippingLabel
                    imgPath={labelImgPath}
                    className="max-h-[4.5in] max-w-[7in]"
                    handleLoad={( e ) => handleLabelImageLoad( e )}
                    handleError={( e ) => {
                      setIsPrintable( false )
                      if ( !handleLabelImageError ) return
                      handleLabelImageError( e )
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div data-testid="packing-slip-v2" className="h-1/2 relative p-[0.25in] text-black">
                <div className="w-full flex flex-row gap-x-6 border-b-2 border-gb-gray-300 pb-2 mb-3 justify-between">
                  {hasWhiteLabelLogo ? (
                    <img
                      src={whiteLabelLogoPath}
                      alt={`${merchantName} logo`}
                      className="max-h-[0.5in] max-w-[3in]"
                    />
                  ) : (
                    <Logo type={LogoType.STACKED} className="w-full max-h-[0.5in] max-w-[0.8in]" />
                  )}
                  {hasWhiteLabelLogo && <WeShipWithGoldbellyV2 className="h-[0.4in] flex-shrink" />}
                  <PackageInfo
                    {...packageInfoProps}
                    className="flex-shrink"
                    showBarcode={showPackageBarcode}
                    version={version}
                    size="small"
                  />
                </div>

                <Grid className="gap-2">
                  <PackingSlipSection
                    icon={hasGiftMessage ? faGift : faBoxHeart}
                    prefix={`For ${recipientsName}`}
                    suffix={sendersName && `From ${sendersName}`}
                    className="col-span-6"
                    nameForTests="gift-message"
                  >
                    {giftMessage && <p>{giftMessage}</p>}
                  </PackingSlipSection>

                  <div className="col-span-6 row-span-4">
                    <PackingSlipSection
                      icon={checklistIcon}
                      className="relative"
                      nameForTests="package-contents"
                    >
                      <div
                        className={classNames(
                          'max-h-[3.5in] w-full overflow-hidden',
                          useHugeStamp && 'w-3/4'
                        )}
                        ref={contentsElem}
                      >
                        <PackingSlipPackageContents
                          lineItems={packageContents}
                          merchantName={merchantName}
                          version={version}
                        />
                      </div>
                      {listTooLong && (
                        <div className="absolute z-20 inset-x-0 bottom-0 p-2">
                          <PackageNotice orderId={packageInfoProps.orderId} version={version} />
                        </div>
                      )}
                      {deliveryCountry === 'CA' && (
                        <div
                          className={classNames(
                            hasSKUs ? '-top-6' : '-top-3',
                            'absolute -right-3 z-10'
                          )}
                        >
                          <img
                            src={usaToCan}
                            className={classNames( useHugeStamp ? 'h-24' : 'h-16' )}
                            alt="This package is being shipped to Canada!"
                            data-testid="stamp-ship-to-canada"
                          />
                        </div>
                      )}
                    </PackingSlipSection>
                  </div>

                  <PackingSlipSection
                    icon={faPlateUtensils}
                    prefix="Prep & Storage Info"
                    iconAlignment="center"
                    imageUrl={qrCodeImgPath}
                    className="col-span-6"
                    nameForTests="prep-storage-info"
                  >
                    <p>Scan the QR code for instructions</p>
                  </PackingSlipSection>

                  <PackingSlipSection
                    icon={faQuestionCircle}
                    className="col-span-6"
                    nameForTests="help-and-support"
                  >
                    {customInstructions || (
                      <p>
                        Visit <strong>help.goldbelly.com</strong> for order questions!
                      </p>
                    )}
                  </PackingSlipSection>
                </Grid>

                <div className="border-b border-gb-gray-700 border-dashed w-full pb-1 absolute inset-x-0 bottom-0 text-center text-2xs text-gb-gray-800">
                  Please include the above slip inside the package
                </div>
              </div>
              <div className="h-1/2 flex items-center justify-around">
                {labelImgPath && (
                  <>
                    <ShippingLabel
                      imgPath={labelImgPath}
                      forceOrientation={addDryIceSticker ? 'portrait' : undefined}
                      prescribedWidthInInches={addDryIceSticker ? 3.25 : 7}
                      prescribedHeightInInches={addDryIceSticker ? 4.75 : 4.5}
                      handleLoad={( e ) => handleLabelImageLoad( e )}
                      handleError={( e ) => {
                        setIsPrintable( false )
                        if ( !handleLabelImageError ) return
                        handleLabelImageError( e )
                      }}
                    />
                    {addDryIceSticker &&
                      ( showDryIcePlaceholder ? (
                        <div className="w-[3.25in]">
                          <DryIceStickerPlaceholder
                            weightInKg={dryIceWeightInKg}
                            packageId={packageInfoProps.packageId}
                          />
                        </div>
                      ) : (
                        <div className="w-[4in]">
                          <DryIceSticker weightInKg={dryIceWeightInKg} />
                        </div>
                      ))}
                  </>
                )}
              </div>
            </>
          )}
        </PrintablePage>
      </div>
    </HideWhenNotPrintable>
  )
}

export default USLetter
