import classNames from 'classnames'
import { FC } from 'react'

import Barcode from 'src/components/01-atoms/Barcode'
import { dayOfTheWeek, shortDate } from 'src/utils/helpers/date'
import { IVersioned } from 'src/utils/types/Versioned'

export interface IPackageInfoProps extends IVersioned {
  /**
   * The id of the suborder/package.
   */
  packageId: string

  /**
   * The id of the order.
   */
  orderId: string

  /**
   * The date on which the package is scheduled to ship.
   */
  toShipOn: Date

  /**
   * The size of the package info.
   */
  size?: 'small' | 'default'

  /**
   * Whether or not to hide the barcode.
   */
  showBarcode?: boolean

  /**
   * The location of the barcode.
   */
  barcodeLocation?: 'top' | 'left'

  /**
   * The name of the merchant.
   */
  merchantName?: string

  /**
   * Classes to pass down to the containing element.
   */
  className?: string
}

const PackageInfo: FC<IPackageInfoProps> = ({
  packageId,
  orderId,
  toShipOn,
  showBarcode = false,
  barcodeLocation = 'left',
  merchantName,
  size = 'default',
  version = 1,
  className,
}) =>
  version === 1 ? (
    <div className={classNames( 'text-black font-semibold', className )} data-testid="package-info">
      {showBarcode && (
        <Barcode value={packageId} className={classNames({ 'text-3xl': size === 'small' })} />
      )}
      <div className="uppercase">
        {merchantName && (
          <div
            className={classNames( 'font-bold', {
              'text-sm': size === 'default',
              'text-xs': size === 'small',
            })}
          >
            {merchantName} Order
          </div>
        )}
        <div
          className={classNames( 'font-bold', {
            'text-sm': size === 'default',
            'text-xs': size === 'small',
          })}
        >
          Package #{packageId}
        </div>
        <div
          className={classNames({ 'text-xs': size === 'default', 'text-2xs': size === 'small' })}
        >
          <span className="font-bold">Customer Order</span> #{orderId}
        </div>
        <div
          className={classNames({ 'text-xs': size === 'default', 'text-2xs': size === 'small' })}
        >
          <span className="font-bold">Ship On</span> {dayOfTheWeek( toShipOn )}
          {` `}
          {shortDate( toShipOn )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames( 'flex items-end', className, {
        'flex-col gap-1': barcodeLocation === 'top',
        'gap-4': barcodeLocation === 'left',
      })}
    >
      {showBarcode && <Barcode value={packageId} />}
      <div
        className={classNames(
          {
            'text-sm': size === 'default',
            'text-xs': size === 'small',
          },
          'text-black text-right inline-block'
        )}
        data-testid="package-info"
      >
        <div>
          Package{' '}
          <span className="font-bold" data-testid="package-id">
            #{packageId}
          </span>
        </div>
        <div>
          Order{' '}
          <span className="font-bold" data-testid="order-id">
            #{orderId}
          </span>
        </div>
        <div>
          Ship On{' '}
          <span className="font-bold" data-testid="to-ship-on">
            {dayOfTheWeek( toShipOn )} {shortDate( toShipOn )}
          </span>
        </div>
      </div>
    </div>
  )

export default PackageInfo
