import {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useState,
  useRef,
  RefObject,
  useCallback,
} from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useLocalstorageState } from 'rooks'
import { useQuery } from 'urql'
import { loader } from 'graphql.macro'
import Skeleton from 'react-loading-skeleton'
import { isEmpty } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

import MDashHead from 'src/pages/elements/MDashHead'
import Container from 'src/components/01-atoms/Container'
import Alert from 'src/components/01-atoms/Alert'
import ResultsPicker from 'src/components/01-atoms/ResultsPicker'
import SimpleSearch from 'src/components/01-atoms/SimpleSearch'
import Pager from 'src/components/01-atoms/Pager'
import PurchaserListTable from 'src/components/03-organisms/PurchaserListTable'

import useAppParams from 'src/utils/hooks/useAppParams'
import useSet from 'src/utils/hooks/useSet'
import { useShiftSelect } from 'src/utils/hooks/useShiftSelect'
import {
  IGetPurchasersForMerchantQuery,
  IGetPurchasersForMerchantQueryVariables,
} from 'src/graphql/queries/getPurchasersForMerchant.types'
import { stringAsDate } from 'src/utils/helpers/date'

const getPurchasersForMerchant = loader( 'src/graphql/queries/getPurchasersForMerchant.graphql' )

// Putting this here for now, but we can enable it when we turn on exports.
const MAKE_SELECTABLE = false

const PurchasersReport: FC = () => {
  const params = useParams()
  const { userId, mdashAccountId, makeLinkUrls, isAdmin } = useAppParams()
  const [ isCopied, setIsCopied ] = useState( false )
  const selectAllRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>
  const [ tableSearchValue, setTableSearchValue ] = useState<string>( '' )
  const [ numPerPage ] = useLocalstorageState( 'mdx-purchasers-num-per-page', 25 )
  const [ page, setPage ] = useState( 0 )

  const [{ data, fetching }] = useQuery<
    IGetPurchasersForMerchantQuery,
    IGetPurchasersForMerchantQueryVariables
  >({
    query: getPurchasersForMerchant,
    variables: {
      mdashAccountId,
      query: tableSearchValue,
      limit: numPerPage,
      page,
    },
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1,
  })

  const accounts =
    data?.purchaserAccountsTable?.purchaserAccounts?.collection.map(
      ({ createdAt, lastPurchaseAt, isGuestAccount, ...a }) => ({
        ...a,
        createdAt: stringAsDate( createdAt ),
        lastPurchaseAt: stringAsDate( lastPurchaseAt ),
        isGuest: isGuestAccount,
      })
    ) || []
  const totalPurchasers = data?.purchaserAccountsTable?.purchaserAccounts?.metadata?.totalCount || 0
  const totalPages = data?.purchaserAccountsTable?.purchaserAccounts?.metadata?.totalPages || 1
  const accountIds: string[] = accounts.map(( x ) => x.id ) || []
  const hasResults = accounts && accounts.length > 0

  const {
    set: selectedAccounts,
    reset: deselectAllAccounts,
    addMultiple: selectAccounts,
    removeMultiple: deselectAccounts,
  } = useSet<string>( new Set())

  const resetSelection = useCallback(() => {
    deselectAllAccounts()
    if ( isEmpty( selectAllRef ) || !selectAllRef?.current ) return
    selectAllRef.current.checked = false
  }, [ deselectAllAccounts ])

  const resetAndGoToFirstPage = () => {
    setPage( 0 )
    resetSelection()
  }

  useEffect(() => {
    resetAndGoToFirstPage()
  }, [ numPerPage, tableSearchValue ])

  useEffect(() => {
    resetSelection()
  }, [ page ])

  const copyableAccountIds = useMemo(
    () => `account_ids = [${accountIds.join( ', ' )}];`,
    [ accountIds ]
  )

  const onChange = useShiftSelect( accountIds, selectAccounts, deselectAccounts )

  if ( !isAdmin && !!userId && !!mdashAccountId && params.mdashAccountId !== mdashAccountId ) {
    return <Navigate to={makeLinkUrls().manifest} replace />
  }

  return (
    <>
      <MDashHead pageTitle="Purchasers" />
      <Container className="py-6">
        <h1 className="text-2xl font-semibold mb-4">Purchasers</h1>
        <div className="flex flex-col gap-4 center-h justify-between xl:flex-row mb-4">
          <div className="lg:w-1/2 xl:w-1/3">
            <SimpleSearch
              disabled={fetching}
              defaultValue={tableSearchValue}
              placeholder="Search purchasers"
              labelText="Search purchasers"
              helperText="For best results, search by purchaser's email or full name."
              handleSubmit={( value: string ) => setTableSearchValue( value )}
              handleClear={() => setTableSearchValue( '' )}
              asStandaloneForm
            />
          </div>
        </div>
        {fetching && !hasResults && <Skeleton height="70vh" width="100%" />}
        {!( hasResults || fetching ) && (
          <div data-testid="alert-no-purchasers">
            <Alert type="info">No purchaser accounts found</Alert>
          </div>
        )}
        {hasResults && (
          <div className="relative">
            {fetching && <div className="absolute inset-0 bg-white opacity-50 z-20" />}
            <div className="flex justify-between items-center mb-4">
              <button
                data-testid="purchasers-count"
                type="button"
                className="text-sm font-semibold flex"
                onClick={() => {
                  navigator.clipboard.writeText( copyableAccountIds )
                  setIsCopied( true )
                  setTimeout(() => setIsCopied( false ), 3000 )
                }}
              >
                {MAKE_SELECTABLE ? `${selectedAccounts.size} selected | ` : ''}
                {totalPurchasers.toLocaleString()} total purchasers
                {isCopied && (
                  <div className="text-gb-blue-600">
                    <FontAwesomeIcon icon={faCheck} className="pl-2 pr-1" />
                    Account IDs Copied
                  </div>
                )}
              </button>
              <ResultsPicker
                localStorageKey="mdx-purchasers-num-per-page"
                defaultValue={25}
                disabled={fetching}
              />
            </div>
            <PurchaserListTable
              purchasersAreSelectable={MAKE_SELECTABLE}
              purchasers={accounts.map(( a ) => ({
                ...a,
                firstName: a.firstName ?? '',
                lastName: a.lastName ?? '',
                email: a.email ?? '',
              }))}
              selectAllRef={selectAllRef}
              selectAllHandleChange={( e ) => {
                if ( e.currentTarget.checked ) {
                  selectAccounts( accountIds )
                } else {
                  deselectAllAccounts()
                }
              }}
              selectedPurchasers={selectedAccounts}
              selectPurchaserChange={( e: ChangeEvent<HTMLInputElement>, purchaserId ) => {
                onChange( e, purchaserId )
              }}
              tableFooterChildren={
                <div className="w-full flex justify-between">
                  <ResultsPicker
                    localStorageKey="mdx-purchasers-num-per-page"
                    defaultValue={25}
                    disabled={fetching}
                  />
                  {totalPages > 1 && (
                    <Pager
                      pageCount={totalPages}
                      onPageChange={({ selected }) => setPage( selected )}
                      forcePage={page}
                      data-testid="manifest-pager"
                    />
                  )}
                </div>
              }
            />
          </div>
        )}
      </Container>
    </>
  )
}

export default PurchasersReport
